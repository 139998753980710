import {ADD_PATIENT_DATA, SET_ERROR, CLEAR_ERROR, CLEAR_MSG, ADD_PATIENT_HEALTH_RECORD, GET_PATIENT_PROFILE, ADD_TO_FAVOURITES, REMOVE_FROM_FAVOURITES, BOOK_CLINIC_APPOINTMENT, FETCH_ALL_APPOINTMENTS} from './types';



export const addPatientData = (apiPayload) => ({
  type: ADD_PATIENT_DATA,
  payload: apiPayload,
});
export const addPatientHealthRecord = (apiPayload) => ({
  type: ADD_PATIENT_HEALTH_RECORD,
  payload: apiPayload,
});

export const addToFavourites = (apiPayload) => ({
  type:ADD_TO_FAVOURITES,
  payload:apiPayload
})

export const bookClinicAppointment = (apiPayload) => ({
  type: BOOK_CLINIC_APPOINTMENT,
  payload: apiPayload
})

export const fetchAllAppointments = (apiPayload) => ({
  type:FETCH_ALL_APPOINTMENTS,
  payload: apiPayload
})

export const removeFromFavourites = (apiPayload) => ({
  type:REMOVE_FROM_FAVOURITES,
  payload:apiPayload
})

export const getPatientProfile = (apiPayload) => ({
  type: GET_PATIENT_PROFILE,
  payload: apiPayload,
});

export const setError = (err) => ({
  type: SET_ERROR,
  payload: err,
});
export const clearError = () => ({
  type: CLEAR_ERROR,
});
export const clearMsg = () => ({
  type: CLEAR_MSG,
});

