import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { GetUserLocality } from "../../Utils/getUserLocation";
import {Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Constants } from "../../Constants";

const libraries = ["places"]; 

export const Bannersearch = () => {
  const { doctorspecialityList } = useSelector((state) => state.doctor);
  const [startDate, setStartDate] = useState(null); 
  const navigate = useNavigate();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [specialitySelected, setSpecialitySelected] = useState("");
  const [locality, setLocality] = useState('');

  const calendarRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  

  const handleSearch = () => {
    navigate("/find-doctor", {
      state: { date: startDate, name: specialitySelected, location: locality },
    });
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setLocality(place.formatted_address);
    }
  };

  const handleInputChange = (event) => {
    
    setLocality(event.target.value); 
  };

  return (
    <>
      <div className="relative md:flex items-center py-4 mt-6">
        <div className="md:flex gap-2 shadow-md overflow-hidden bannersearch">
          <div className="flex items-center space-x-4 px-4 py-2 fromfield">
            <i className="fa-sharp fa-solid fa-location-dot"></i>
            {isLoaded && (
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  type="text"
                  value={locality || GetUserLocality() }
                  onChange={handleInputChange} 
                  placeholder="Location"
                  className="outline-none form-input"
                />
              </Autocomplete>
            )}
          </div>

          <div
            className="relative max-w-sm space-y-3 "
            onClick={() => setCalendarOpen(true)}
          >
            <div className="flex h-full">
              <div className="px-4 py-2 inline-flex items-center min-w-fit rounded-s-md border border-e-0 bg-transparent">
                <i className="fa-solid fa-calendar text-white"></i>
              </div>
              <input
                type="text"
                className="border outline-none bg-transparent rounded-tr-md rounded-br-md text-center text-white font-bold"
                placeholder="Select Date"
                value={
                  startDate !== null ? moment(startDate).format("DD/MM/YYYY") : ""
                }
              />
            </div>
          </div>

          <div className="flex items-center space-x-4 px-4 py-2 fromfield">
            <i className="fa-regular fa-bars"></i>
            <select
              onChange={(e) => setSpecialitySelected(e.target.value)}
              className="w-full py-2 px-4 outline-none form-input shadow-sm custom-scrollbar"
            >
              <option value="" className="text-gray-500">
                Specialities
              </option>
              {doctorspecialityList.map((itx) => (
                <option key={itx.id} value={itx.name} className="text-gray-900">
                  {itx.name}
                </option>
              ))}
            </select>
          </div>
          <button
            type="button"
            onClick={handleSearch}
            className="bg-primary text-white px-9 py-2 btn rounded transition-all duration-300 hover:bg-blue-700"
          >
            Search
          </button>
        </div>
        {calendarOpen && (
          <div ref={calendarRef}>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setCalendarOpen(false); // Close calendar on date select
              }}
              minDate={new Date()}
              inline
            />
          </div>
        )}
      </div>
    </>
  );
}
