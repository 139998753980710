import { Grid } from '@mui/material'
import React from 'react'

export default function Forgotpassword() {
  return (
    <>
    <section className="py-20 border-t-2 bg-slate-50">
        <div className="container mx-auto px-4">
          <form>
            <Grid container columns={12} className="justify-center">
              <Grid item xs={12} md={8} lg={6} xl={5}>
                <div className="p-9 bg-white formsignin shadow-lg">
                    <h2 className="lg:text-3xl md:text-2xl text-2xl font-bold mb-6">
                    Forgot Password
                    </h2>
                    <div className="mb-4">
                        <label> Email</label>
                        <div className="mt-2">
                            <input type="text" class="border-2 border-black w-full py-4 px-4 rounded-lg text-sm border-black" placeholder="Enter your email address" name=""/>
                        </div>
                    </div>
                    <div class="mb-4">
                        <button class="w-full bg-primary rounded-lg text-white font-semibold py-4 px-4 hover:bg-black transition-all delay-200">Continue</button>
                    </div>
                    <div className='mb-4'>
                        <p>Remembered your password? <a href='/signin' className='font-bold text-primary'>Sign In</a></p>
                    </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </section>
    </>
  )
}
