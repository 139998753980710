import {
  SIGNIN_SUCCESS,
  REGISTER_SUCCESS,
  LOGOUT,
  SET_ERROR,
  CLEAR_ERROR,
  CLEAR_MSG,
  CHANGE_USER_PASSWORD,
  STORE_USER_LOCATION,

} from "../Actions/Auth/types";

const initialState = {
  token: localStorage.getItem("token") || null,
  user: JSON.parse(localStorage.getItem("userInfo")) || null,
  location:'',
  msg: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_SUCCESS: // Updated action type usage
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.userInfo,
        msg: action.payload.message,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        msg: action.payload.message,
      };
    case CHANGE_USER_PASSWORD:
      return {
        ...state,
        msg: action.payload.message,
      };
    case STORE_USER_LOCATION:
      return {
        ...state,
        location:action.payload
      }
 
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MSG:
      return {
        ...state,
        msg: null,
      };
    default:
      return state;
  }
};

export default authReducer;
