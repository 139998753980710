import { SIGNIN_SUCCESS, REGISTER_SUCCESS, LOGOUT, SET_ERROR, CLEAR_ERROR, CHANGE_USER_PASSWORD, CLEAR_MSG, STORE_USER_LOCATION, CLEAR_DATA} from './types';

export  const signInSuccess = (payLoadData) => ({
  type: SIGNIN_SUCCESS, // Updated usage
  payload: payLoadData,
});

export const registerSuccess = (apiPayload) => ({
  type: REGISTER_SUCCESS,
  payload: apiPayload,
});

export const changeUserPassword = (data) => ({
  type: CHANGE_USER_PASSWORD,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT,
});

export const storeUserLocation = () => ({
  type:STORE_USER_LOCATION
})


export const setError = (err) => ({
  type: SET_ERROR,
  payload: err,
});
export const clearError = () => ({
  type: CLEAR_ERROR,
});
export const clearMsg = () => ({
  type: CLEAR_MSG,
});
