import React from 'react'
import BannerDashboard from './BannerDashboard'
import { Grid } from '@mui/material'
import ToggleNavs from '../ToggleNavs/toggleNavs'

export default function Transactions() {
  return (
    <>
        <BannerDashboard pageTitle="Transactions and Payments"/>
        <section className="py-8 bg-slate-50">
            <div className="container mx-auto px-4">
                <Grid container columns={12} spacing={4}>
                    <Grid item xs={12} md={4} lg={3}>
                        <ToggleNavs />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        <h2 className='text-2xl font-semibold  pb-3 mb-6 border-b'>Transactions and Payments</h2>
                        <div className='overflow-x-auto'>
                            <table class="border table-auto w-full text-xs shadow-lg">
                                <thead>
                                    <tr>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Date</th>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Description</th>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Status</th>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Amount</th>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Payment method</th>
                                        <th class="border-b dark:border-slate-800 font-medium px-4 py-4 text-slate-700 dark:text-slate-200 text-left">Transation ID</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white dark:bg-slate-800">
                                    <tr>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">05-04-2024</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Monthly Subscription</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Processing</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">+$290 (USD)</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Credit Card</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">TXIDHJHKYTOPLK</td>
                                    </tr>
                                    <tr>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">05-04-2024</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Monthly Subscription</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Processing</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">+$290 (USD)</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Credit Card</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">TXIDHJHKYTOPLK</td>
                                    </tr>
                                    <tr>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">05-04-2024</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Monthly Subscription</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Processing</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">+$290 (USD)</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Credit Card</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">TXIDHJHKYTOPLK</td>
                                    </tr>
                                    <tr>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">05-04-2024</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Monthly Subscription</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Processing</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">+$290 (USD)</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">Credit Card</td>
                                        <td class="border-b border-slate-100 dark:border-slate-700 px-4 py-3 text-slate-600 dark:text-slate-400">TXIDHJHKYTOPLK</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    </>
  )
}
