import { Grid, Rating, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../Constants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { GetReviewStarRating } from "../../Utils/getReviewStarRating";
import {
  AddToFavourites,
  RemoveFromFavourites,
} from "../../Thunk/patientThunks";
export default function DrCard({
  doctorLists,
  name,
  selectedHeart,
  setSelectedHeart,
}) {
  const navigate = useNavigate();
  const { msg } = useSelector((state) => state.user);
  const { user, token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const handleAddFavourites = (data) => {
    if (user && token && data) {
      if (!selectedHeart.includes(data._id)) {
        setSelectedHeart((prevSelectedHeart) => [
          ...prevSelectedHeart,
          data._id,
        ]);
        dispatch(AddToFavourites({ Id: user.PatientId, favourites: data._id }));
      } else {
        dispatch(
          RemoveFromFavourites({ Id: user.PatientId, favourites: data._id })
        ).then((resp) => {
          setSelectedHeart(resp.Favourites);
        });
      }
    } else {
      toast.error("Sign In is required");
      setTimeout(() => {
        navigate("/signin");
      }, 1200);
    }
  };
  useEffect(() => {
    if (msg) {
      toast.success(msg);
    }
  }, [msg]);

  return (
    <>
      <Grid container columns={12} spacing={3} alignItems="center">
        {doctorLists?.length > 0 && doctorLists !== undefined ? (
          doctorLists.map((itx) => (
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              lg={name == "favourites" || name == "speciality" ? 4 : 3}
            >
              <div className="p-4 drCard">
                <a href="#" className="drcardImg">
                  <img
                    src={
                      itx.profilePic
                        ? `${Constants.getImg}${itx.profilePic}`
                        : "assets/images/dr1.png"
                    }
                  />
                </a>
                {user && user.userType === "Doctor" ? null : (
                  <button
                    type="button"
                    onClick={() => handleAddFavourites(itx)}
                    className={
                      selectedHeart?.includes(itx._id)
                        ? "wishlist-active"
                        : "wishlist"
                    }
                  >
                    <i class="fa-sharp fa-solid fa-heart"></i>
                  </button>
                )}
                {itx.Ratings?.length > 0 && (
                  <div className="flex items-center">
                    <Rating
                      size="small"
                      defaultValue={GetReviewStarRating(itx.Ratings)}
                      precision={0.5}
                      readOnly
                    />
                    <div className="ml-3 rounded-full bg-indigo-800 px-2 text-white text-xs py-0">
                      {GetReviewStarRating(itx.Ratings)}
                    </div>
                  </div>
                )}
                <h2 className="text-lg font-bold my-2">
                  <a href="#">{itx.Name}</a>
                </h2>
                <p>
                  {itx.ProfessionalInformation?.Degree.substring(0, 12) +
                    "...."}{" "}
                  - {itx.ProfessionalInformation?.Specializations[0]}
                </p>
                <p>
                  {itx.ProfessionalInformation?.work_experience} years
                  experience
                </p>
                <p>$ {itx.Fares.online_fare} Consultation fee</p>
                <p className="text-sm">
                  <i class="fa-solid text-orange-800 fa-location-dot"></i>{" "}
                  {itx.Address.substring(0,33) + '..'}
                </p>
                <div className="flex gap-2 items-center mt-4">
                  <div className="w-1/2">
                    <Link
                      to={"/dr-details"}
                      state={{ details: itx }}
                      className="bg-violet-200 text-primary rounded-full  py-2 text-xs block text-center font-semibold hover:bg-indigo-600 hover:text-white"
                    >
                      View Profile
                    </Link>
                  </div>
                  <div className="w-1/2">
                    <Link
                      to={"/dr-details"}
                      state={{ details: itx }}
                      className="bg-primary text-white rounded-full py-2 px-1  text-xs block text-center font-semibold hover:bg-black"
                    >
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          ))
        ) : (
          <>
            {name === "favourites" ? (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", width: "100%", marginTop: "15px" }}
              >
                No Favourites Found !!
              </Typography>
            ) : name == "speciality" ? (
              <Typography
                variant="h6"
                sx={{ textAlign: "center", width: "100%", marginTop: "15px" }}
              >
                No Doctors Found
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{ textAlign: "center", width: "100%", marginTop: "15px" }}
              >
                “We’re actively working to onboard healthcare professionals.
                <br />
                Check back soon for updates!”
              </Typography>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
