
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'; 
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const STORE_USER_LOCATION = 'STORE_USER_LOCATION'
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_MSG = 'CLEAR_MSG';

