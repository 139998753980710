import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Reducers/authReducer'
import patientReducer from './Reducers/patientReducer'
import doctorReducer from './Reducers/doctorReducer'
import {thunk} from 'redux-thunk';
const store = configureStore({
  reducer: {
    auth: authReducer,
    user: patientReducer,
    doctor: doctorReducer
  },
 
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
