export const ConvertToFormData = async (data) => {
  const formData = new FormData();
  const ProfessionalInformation = {};
  const LicensingInformation = {};

  if (typeof data == "object" && data !== null) {
    for (const key in data) {
      if (
        key === "Medical_School_Attended" ||
        key === "Degree" ||
        key === "Specializations" ||
        key === "work_experience"
      ) {
        ProfessionalInformation[key] = data[key];
      }
      if (
        key !== "Medical_License_Number" &&
        key !== "Issuing_State" &&
        key !== "Issuing_Country" &&
        key !== "dt_of_issue" &&
        key !== "Medical_School_Attended" &&
        key !== "Degree" &&
        key !== "Specializations" &&
        key !== "work_experience" &&
        key !== "languages_can_speak" &&
        key !== "clinics"
      ) {
        formData.append(key, data[key]);
      }
      if (key === "languages_can_speak") {
        formData.append(key, JSON.stringify(data[key]));
      }
      if (key === "clinics") {
        formData.append('Clinics', JSON.stringify(data[key].map((itx) => {
          return {
            clinic_name:itx.clinic_name,
            clinic_address:itx.clinic_address
          }
        })));
      }
      if (key === "Degree_Certificate") {
        formData.append(key, data[key][0]);
      }
      if (
        key === "Medical_License_Number" ||
        key === "Issuing_State" ||
        key === "Issuing_Country" ||
        key === "dt_of_issue"
      ) {
        LicensingInformation[key] = data[key];
      }
    }
    formData.append(
      "ProfessionalInformation",
      JSON.stringify(ProfessionalInformation)
    );
    formData.append(
      "LicensingInformation",
      JSON.stringify(LicensingInformation)
    );
  }
  for (const pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
  return formData;
};
