import React, { useEffect, useMemo, useState } from "react";
import InnerBanner from "../InnerBannersearch/InnerBannersearch";
import { Grid } from "@mui/material";
import SearchFilter from "./SearchFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllDoctorLists,
  GetDoctorSpecialityInfo,
} from "../../Thunk/doctorThunks";
import { useLocation } from "react-router-dom";
import DrCard from "../Specialistdr/DrCard";
import { sortingConditions } from "../../Utils/StaticData";
import { useForm } from "react-hook-form";
import { GetUserCity, GetUserLocality} from "../../Utils/getUserLocation";

export default function Finddoctor() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { doctorLists } = useSelector((state) => state.doctor);
  const [selectedHeart, setSelectedHeart] = useState(
    JSON.parse(localStorage.getItem("userInfo"))?.Favourites || []
  );
  console.log("doctList", doctorLists);
  const { state } = location;
  const { register, watch, setValue, getValues } = useForm();
  const sortField = watch("sortField");
  const specialityField = watch("speciality");
  const feesField = watch("fees");
  const availabilityField = watch("availability");
  const experienceField = watch("experience");

  console.log(
    "speciality",
    specialityField + " " + "fees",
    feesField + " " + "availablity",
    availabilityField + " " + "exp",
    experienceField
  );
  useEffect(() => {
    dispatch(GetAllDoctorLists());
  }, []);

  useEffect(() => {
    dispatch(GetDoctorSpecialityInfo());
  }, []);

  const filteredDoctors = useMemo(() => {
    let doctors = doctorLists.filter((itx) => itx.Address.includes(GetUserCity()));
    if (state.name !== "All") {
        doctors = doctors.filter((itx) =>
            itx.ProfessionalInformation.Specializations.includes(state.name)
        );
    }

    if (specialityField) {
      doctors = doctors.filter((itx) =>
        itx.ProfessionalInformation.Specializations.includes(specialityField)
      );
    }

    // Apply the fees filter
    if (feesField) {
        const [minFee, maxFee] = (() => {
            switch (feesField) {
                case "$0-$500":
                    return [0, 500];
                case "Above $500":
                    return [500, 1000];
                case "Above $1000":
                    return [1000, 2000];
                case "Above $2000":
                    return [2000, Infinity];
                default:
                    return [0, Infinity];
            }
        })();
        doctors = doctors.filter((itx) => {
            const fare = parseFloat(itx.Fares.clinic_fare);
            return fare > minFee && fare <= maxFee;
        });
    }

    // Apply the experience filter
    if (experienceField) {
        const [minExp, maxExp] = (() => {
            switch (experienceField) {
                case "5+":
                    return [5, 10];
                case "10+":
                    return [10, 15];
                case "15+":
                    return [15, 20];
                case "20+":
                    return [20, Infinity];
                default:
                    return [0, Infinity];
            }
        })();
        doctors = doctors.filter((itx) => {
            const experience = parseFloat(itx.ProfessionalInformation.work_experience);
            return experience > minExp && experience <= maxExp;
        });
    }

    if (sortField === "Fees Low-High") {
        doctors.sort((a, b) =>
            parseFloat(a.Fares.clinic_fare) - parseFloat(b.Fares.clinic_fare)
        );
    } else if (sortField === "Fees High-Low") {
        doctors.sort((a, b) =>
            parseFloat(b.Fares.clinic_fare) - parseFloat(a.Fares.clinic_fare)
        );
    } else if (sortField === "Experience High-Low") {
        doctors.sort((a, b) =>
            parseFloat(b.ProfessionalInformation.work_experience) -
            parseFloat(a.ProfessionalInformation.work_experience)
        );
    } else if (sortField === "Experience Low-High") {
        doctors.sort((a, b) =>
            parseFloat(a.ProfessionalInformation.work_experience) -
            parseFloat(b.ProfessionalInformation.work_experience)
        );
    }

    return doctors;
}, [doctorLists, state.name, sortField, specialityField, experienceField, feesField]);

  return (
    <>
      <InnerBanner />
      <section className="bg-slate-100 py-10">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item lg={9} md={8} xs={12} order={{ lg: 2, md: 2 }}>
              <div className="md:flex justify-between items-center">
                <div className="mb-2">
                  <h1 className="text-xl font-semibold">
                    {state.name == "All" ? "Available" : state.name} Doctors in {" "}
                    {GetUserLocality()}
                  </h1>
                  <p className="text-sm font-semibold text-primary mb-3">
                    Showing Result - {filteredDoctors.length}
                  </p>
                </div>
                <div className="flex items-center gap-3 mb-5">
                  <div className="font-semibold text-sm">Sort By</div>
                  <div>
                    <select
                      {...register("sortField")}
                      className="py-2 px-3 text-sm border rounded-lg border-gray-400"
                    >
                      <option>Relevance</option>
                      {sortingConditions.map((itx) => (
                        <option>{itx}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <DrCard
                doctorLists={filteredDoctors}
                name="speciality"
                selectedHeart={selectedHeart}
                setSelectedHeart={setSelectedHeart}
              />
            </Grid>

            <Grid item lg={3} md={4} xs={12} order={{ lg: 1, md: 1 }}>
              <SearchFilter setValue={setValue} getValues={getValues} />
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
