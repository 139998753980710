import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const PathologySection = ({
  register,
  fields,
  isEditable,
  disableCondition,
  onAddHandler,
  onRemoveHandler,
}) => {
  const { patientData } = useSelector((state) => state.user);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className="flex justify-end">
        {!disableCondition && (
          <ControlPointIcon sx={{ cursor: "pointer" }} onClick={onAddHandler} />
        )}
        </div>
      </Grid>
      {fields.map((record, index) => (
        <React.Fragment key={record.id}>
          <Grid item xs={6}>
            <label className="text-sm mb-2 block">Pathology Description</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register(`pathologicalSection.${index}.Pathology_Descr`)}
              disabled={disableCondition}
            />
          </Grid>
          <Grid item xs={6}>
          <Grid container spacing={1}>
              <Grid item xs={10}>
              <label className="text-sm mb-2 block">Certification Date</label>
              </Grid>
              {index !== 0 && !disableCondition && (
                <Grid item xs={1.5}>
                  <RemoveCircleOutlineIcon
                    sx={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginBottom: "3px",
                    }}
                    onClick={() => onRemoveHandler(index)}
                  />
                </Grid>
              )}
            </Grid>
          
            <input
              type="date"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register(`pathologicalSection.${index}.CertifDate`)}
              disabled={disableCondition}
            />
          </Grid>
          <Grid item xs={6}>
            <label className="text-sm mb-2 block">Status</label>
            <select
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register(`pathologicalSection.${index}.Status`)}
              disabled={disableCondition}
            >
              <option>Select</option>
              <option>Current</option>
              <option>Previous</option>
            </select>
          </Grid>
          <Grid item xs={6}>
            <label className="text-sm mb-2 block">Certification Document</label>
            {patientData.Health_Records?.PathologicalSection[index]?.CertifDoc && !isEditable ? (
              <div className="flex justify-start items-center">
                <PictureAsPdfIcon />
                <p>
                  {patientData.Health_Records?.PathologicalSection[index]?.CertifDoc.substring(
                    13,
                    25
                  ) }
                </p>
              </div>
            ) : (
              <>
                <label for="file-input" class="sr-only">
                  Choose file
                </label>
                <input
                  type="file"
                  {...register(`pathologicalSection.${index}.CertifDoc`)}
                  class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                file:bg-gray-50 file:border-0
                  file:me-4
                  file:py-3 file:px-4"
                />
              </>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};
export default PathologySection;
