import React, { useEffect, useState } from "react";
import BannerDashboard from "./BannerDashboard";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultiSelectDropdown from "../Multiselect/Multiselect";
import ToggleNavs from "../ToggleNavs/toggleNavs";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ConvertToFormData } from "../../Utils/convertToFormData";
import {
  addDoctorProfile,
  GetDoctorProfile,
  GetDoctorSpecialityInfo,
} from "../../Thunk/doctorThunks";
import toast from "react-hot-toast";
import { ClinicDetailsForm } from "../Forms/ClinicDetailsForm";
export default function DrDashboard() {
  const { user } = useSelector((state) => state.auth);
  const { msg, doctorData, doctorspecialityList } = useSelector(
    (state) => state.doctor
  );
  const [fieldDisability, setFieldDisability] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [SpecializationSelected, setSpecializationSelected] = useState([]);
  const [languageSelected, setLanguageSelected] = useState([]);
  const dispatch = useDispatch();
  const languageOptions = [
    "English",
    "Hindi",
    "Bengali",
    "Nepali",
    "Spanish",
    "French",
    "German",
  ];

  useEffect(() => {
    if (doctorData) {
      setFieldDisability(doctorData.isUpdated ?? false);
    }
  }, [doctorData]);
  useEffect(() => {
    dispatch(GetDoctorSpecialityInfo());
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    values: {
      Name: user.Name,
      DateOfBirth: doctorData.DateOfBirth,
      Gender: doctorData.Gender,
      Address: doctorData.Address,
      PhoneNumber: doctorData.PhoneNumber,
      Email: user.email,
      Medical_School_Attended:
        doctorData.ProfessionalInformation?.Medical_School_Attended,
      Degree: doctorData.ProfessionalInformation?.Degree,
      Degree_Certificate:
        doctorData.ProfessionalInformation?.Degree_Certificate,
      clinics: doctorData.Clinics?.map((item) => {
        return {
          clinic_name: item.clinic_name,
          clinic_address: item.clinic_address,
        };
      }) || [{ clinic_name: "", clinic_address: "" }],
      Medical_License_Number:
        doctorData.LicensingInformation?.Medical_License_Number,
      Issuing_State: doctorData.LicensingInformation?.Issuing_State,
      Issuing_Country: doctorData.LicensingInformation?.Issuing_Country,
      dt_of_issue: doctorData.LicensingInformation?.dt_of_issue,
      work_experience: doctorData.ProfessionalInformation?.work_experience,
      Description: doctorData.Description,
    },
  });

  const handleDoctorInfo = async (data) => {
    data["DoctorId"] = user.Id;
    data["isUpdated"] = true;
    console.log(data);
    let formData = await ConvertToFormData(data);
    dispatch(addDoctorProfile(formData));
    setFieldDisability(true);
    setIsEditing(false);
  };
  const onEditHandler = () => {
    setFieldDisability(false);
    setIsEditing(true);
  };
  useEffect(() => {
    if (msg) {
      toast.success(msg);
      reset();
    }
  }, [msg]);

  useEffect(() => {
    dispatch(GetDoctorProfile(user.Id)).then((data) => {
      setSpecializationSelected(
        data?.ProfessionalInformation?.Specializations || []
      );
      setLanguageSelected(data?.languages_can_speak || []);
    });
  }, [msg]);

  const {
    fields: clinicFields,
    append: appendClinic,
    remove: removeClinic,
  } = useFieldArray({
    control,
    name: "clinics",
  });

  const addNewClinicRecord = () => {
    appendClinic({
      clinic_name: "",
      clinic_address: "",
    });
  };

  const removeClinicRecord = (index) => {
    removeClinic(index);
  };

  return (
    <>
      <BannerDashboard pageTitle="Dashboard" />
      <section className="py-8">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h2 class="text-2xl font-semibold  pb-3 mb-6 border-b">
                Dashboard
              </h2>
              <div className="border rounded-lg shadow-lg p-6">
                <form onSubmit={handleSubmit(handleDoctorInfo)}>
                  <div className="flex justify-between">
                    <h3 className="mb-4 font-bold text-lg">
                      Personal Information
                    </h3>
                    <div>
                      <a
                        className="text-sm hover:cursor-pointer"
                        onClick={onEditHandler}
                      >
                        <i class="fa-regular fa-pen-to-square"></i> Edit
                      </a>
                    </div>
                  </div>
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("Name", {
                          required: "name is required",
                        })}
                        disabled
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.Name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Name.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Date of Birth <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="date"
                        {...register("DateOfBirth", {
                          required: "Date of Birth is required",
                        })}
                        disabled={fieldDisability}
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.DateOfBirth && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.DateOfBirth.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Gender <span className="text-red-600">*</span>
                      </label>
                      <select
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Gender", {
                          required: "Gender is required",
                        })}
                        disabled={fieldDisability}
                      >
                        <option>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                      {errors.Gender && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Gender.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Address <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Address", {
                          required: "Address is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Address && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Address.message}
                        </p>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Phone Number <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("PhoneNumber", {
                          required: "Phone Number is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.PhoneNumber && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.PhoneNumber.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        {...register("Email", {
                          required: "Email is required",
                        })}
                        disabled
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      />
                      {errors.Email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Email.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <label className="text-sm mb-2 block">
                        Clinic Details <span className="text-red-600">*</span>
                      </label>
                      <Accordion sx={{ padding: "0px" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography fontSize="14px" fontWeight="bold">
                            Clinic Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ClinicDetailsForm
                            register={register}
                            fields={clinicFields}
                            // isEditable={isEditable}
                            errors={errors}
                            onAddHandler={addNewClinicRecord}
                            onRemoveHandler={removeClinicRecord}
                            disableCondition={fieldDisability}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                  <h3 className="my-4 font-bold text-lg">
                    Professional Information
                  </h3>
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Medical School Attended{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Medical_School_Attended", {
                          required: "Medical School Attended is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Medical_School_Attended && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Medical_School_Attended.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Degree <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Degree", {
                          required: "Degree is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Degree && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Degree.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Specializations <span className="text-red-600">*</span>
                      </label>
                      <MultiSelectDropdown
                        options={doctorspecialityList}
                        selectedOptions={SpecializationSelected}
                        setSelectedOptions={setSpecializationSelected}
                        fieldDisability={fieldDisability}
                        setValue={setValue}
                        name="Specializations"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <label className="text-sm mb-2 block">
                        Upload Certificate{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      {doctorData.ProfessionalInformation?.Degree_Certificate &&
                      !isEditing ? (
                        <div className="flex justify-start items-center">
                          <PictureAsPdfIcon />
                          <p>
                            {doctorData.ProfessionalInformation?.Degree_Certificate.substring(
                              13,
                              25
                            )}
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="flex gap-3">
                            <div>
                              <label for="file-input" class="sr-only">
                                Choose file
                              </label>
                              <input
                                type="file"
                                {...register("Degree_Certificate")}
                                class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
                              file:bg-gray-50 file:border-0
                                file:me-4
                                file:py-3 file:px-4"
                              />
                            </div>
                            <div>
                              <div>
                                <img
                                  src="assets/images/award-3.png"
                                  className="h-11 w-11 object-contain border border-slate-400 rounded"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <h3 className="my-4 font-bold text-lg">
                    Licensing Information
                  </h3>
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Medical License Number{" "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Medical_License_Number", {
                          required: "License Number is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Medical_License_Number && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Medical_License_Number.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Issuing State <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Issuing_State", {
                          required: "Issuing State is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Issuing_State && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Issuing_State.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Issuing Country <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("Issuing_Country", {
                          required: "Issuing Country is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Issuing_Country && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Issuing_Country.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Date of Issue <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="date"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("dt_of_issue", {
                          required: "Date of Issue is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.dt_of_issue && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.dt_of_issue.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Work Experience <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                        {...register("work_experience", {
                          required: "Work Experience is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.work_experience && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.work_experience.message}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <label className="text-sm mb-2 block">
                        Languages <span className="text-red-600">*</span>
                      </label>
                      <MultiSelectDropdown
                        options={languageOptions}
                        selectedOptions={languageSelected}
                        setSelectedOptions={setLanguageSelected}
                        setValue={setValue}
                        fieldDisability={fieldDisability}
                        name="languages_can_speak"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <label className="text-sm mb-2 block">
                        Description <span className="text-red-600">*</span>
                      </label>
                      <textarea
                        className="border border-slate-400 px-3 h-28 rounded-xl w-full text-sm"
                        {...register("Description", {
                          required: "Description is required",
                        })}
                        disabled={fieldDisability}
                      />
                      {errors.Description && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.Description.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  {!fieldDisability && (
                    <div className="mt-4 text-right">
                      <button
                        type="submit"
                        class=" bg-primary rounded-lg text-white font-semibold py-3 px-8 hover:bg-black transition-all delay-200"
                      >
                        Update Profile
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
