import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signIn } from "../../Thunk/authThunks";
import toast from "react-hot-toast";
export default function Signin() {
  const { register, handleSubmit, formState: { errors } } = useForm();
 
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, msg } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (msg === "User logged in successfully") {
      navigate("/dashboard");
    }
  }, [error, msg]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSignIn = (data) => {
    dispatch(signIn(data));
  };
  return (
    <>
      <section className="py-20 border-t-2 bg-slate-50">
        <div className="container mx-auto px-4">
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Grid container columns={12} className="justify-center">
              <Grid item xs={12} md={8} lg={6} xl={5}>
                <div className="p-9 bg-white formsignin shadow-lg">
                  <h2 className="lg:text-3xl md:text-2xl text-2xl font-bold mb-6">
                    Sign in to your account
                  </h2>
                  <div className="mb-4">
                    <label>Email</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="email"
                        autoFocus={true}
                        className={`border-2 w-full py-4 px-4 rounded-lg text-sm ${
                          errors.email ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Enter Email Address"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[a-z0-9._-]+@[a-z]+\.[a-z]{2,3}$/,
                            message: "Invalid email format",
                          },
                        })}
                      />

                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label>Password</label>
                    <div className="mt-2 relative" data-tooltip-target>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className={`border-2 border-black w-full py-4 px-4 rounded-lg text-sm ${
                          errors.password ? "border-red-500" : "border-black"
                        }`}
                        placeholder="Enter Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          maxLength: {
                            value: 15,
                            message: "Password must not exceed 15 characters",
                          },
                        })}
                      />

                      {errors.password && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.password.message}
                        </p>
                      )}
                      <button
                        type="button"
                        className="absolute right-3 top-4"
                        onClick={togglePasswordVisibility}
                      >
                        {" "}
                        {showPassword ? (
                          <i class="fa-solid fa-eye-slash"></i>
                        ) : (
                          <i class="fa-solid fa-eye"></i>
                        )}{" "}
                      </button>
                    </div>
                  </div>
                  <div className="mb-4 text-right">
                    <a href="/forgot-password" className="font-semibold text-primary">
                      Forgot Password
                    </a>
                  </div>
                  <div className="mb-4">
                    <button className="w-full bg-primary rounded-lg text-white font-semibold py-4 px-4 hover:bg-black transition-all delay-200">
                      Sign In
                    </button>
                  </div>
                  <div className="text-center">
                    <p>
                      Don’t have an account?{" "}
                      <a
                        href="/register"
                        className="font-semibold text-primary"
                      >
                        Register Now
                      </a>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </section>
    </>
  );
}
