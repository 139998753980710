import React, { useEffect,useState } from 'react';
import Homebanner from './Homebanner';
import Specialities from '../Specialities/Specialities';
import SpecialistDrTab from '../Specialistdr/SpecialistDrTab';
import FaqHome from '../Faq/FaqHome';
import DownloadApp from './DownloadApp';
import Awards from './Awards';
import { useDispatch, useSelector } from 'react-redux';
import { GetDoctorSpecialityInfo } from '../../Thunk/doctorThunks';

const HomePage = React.memo(() => {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.auth);


  useEffect(() => {
    dispatch(GetDoctorSpecialityInfo());
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      console.log('Re-rendering....');
    }
  }, [location]);

  return (
    <>
      <Homebanner  />
      <Specialities />
      <SpecialistDrTab />
      <FaqHome />
      <DownloadApp />
      <Awards />
    </>
  );
});

export default HomePage;
