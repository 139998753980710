
const prefix = 'https://ui.goigi.biz/ago-sibillo-user-api/api/'
const prefixImg = 'https://ui.goigi.biz/ago-sibillo-user-api/uploads/'
const prefixAsset = 'https://ui.goigi.biz/ago-sibillo-user-api/Assets/'
// const prefix = 'http://localhost:8688/api/'
// const prefixImg = 'http://localhost:8688/uploads/'
// const prefixAsset = 'http://localhost:8688/Assets/'
export const placeDetails = "https://maps.googleapis.com/maps/api/geocode/json"
export const Constants = {
  // Auth Related API Endpoints
  signIn : prefix + 'auth/signin',
  register: prefix + 'auth/register',
  addProfilePic: prefix + 'auth/addProfilePic',
  addCoverPic: prefix + 'auth/addCoverImg',
  changePassword: prefix + 'auth/change-password',

  
  // Patient Related API Endpoints
  addPatientProfile: prefix + 'patient/add-patient-profile',
  addPatientHealthProfile: prefix + 'patient/add-patient-health-records',
  addToFavourites: prefix + 'patient/add-to-favourites',
  removeFromFavourites: prefix + 'patient/remove-from-favourites',
  getPatientProfile: prefix + 'patient/get-patient-profile/',
  bookClinicAppointment: prefix + 'patient/book-clinic-appointment',
  fetchAllAppointment: prefix + 'patient/fetch-appointmentsById/',
  

  // Doctor Related API Endpoints
  addDoctorProfile: prefix + 'doctor/add-doctor-profile',
  addDoctorAvailability: prefix + 'doctor/add-doctor-availability',
  GetDoctorProfile: prefix + 'doctor/get-doctor-profile/',
  GetDoctorAvailabilities: prefix + 'doctor/get-doctor-availabilities/',
  GetDoctorSpecialitiesInfo: prefix + 'doctor/get-specialities-info',
  GetAllDoctorLists: prefix + 'doctor/get-all-doctor-profiles',
  addDoctorReviews: prefix + 'doctor/add-reviews',
  fetchAllUpcomingAppointment: prefix + 'doctor/fetch-appointmentsById/',

  // Image Related API Endpoints
  getImg: prefixImg,
  getAsset:prefixAsset
}
