import { Grid } from '@mui/material';
import React from 'react'
import {BrowserRouter as Router,
    Link } from "react-router-dom";
import Chatbot from '../Component/Chatboat/Chatboat';


export default function Footer(props) {
  return (
    <>
      <Chatbot/>
      <footer>
        <div className='footer-top py-9'>
          <div className='container mx-auto px-4'>
            <Grid container columns={12} spacing={4}>
              <Grid item md={12} lg={3}>
                <div className='mb-7'>
                  <a className='footerlogo' href='/'><img src='assets/images/logo.png'/></a>
                </div>
                <p className='text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do incididunt ut labore.</p>
                <div className='flex gap-3 mt-5 items-center'>
                  <a href='#'><img src='assets/images/fb.png' className='h-8 w-8 object-contain'/></a>
                  <a href='#'><img src='assets/images/linkedin.png' className='h-8 w-8 object-contain'/></a>
                  <a href='#'><img src='assets/images/insta.png' className='h-8 w-8 object-contain'/></a>
                  <a href='#'><img src='assets/images/xpath.png' className='h-8 w-8 object-contain'/></a>
                </div>
                <div className='flex gap-3 mt-5 items-center'>
                  <a href='#'><img src='assets/images/google-play-dark.png' className='h-11 object-contain'/></a>
                  <a href='#'><img src='assets/images/appstore-dark.png' className='h-11 object-contain'/></a>
                </div>
              </Grid>
              <Grid item md={12} lg={9}>
                <Grid container columns={12}>
                  <Grid item xs={12} md={4} lg={4}>
                    <h3 className='font-semibold text-xl mb-4'>Who we are</h3>
                    <div>
                      <a href='/who-we-are' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>About us</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Contact Us</a>
                      <a href='/leadership' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Leadership</a>
                      <a href='/faq' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>FAQ</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Blog</a>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}  lg={4}>
                    <h3 className='font-semibold text-xl mb-4'>Company</h3>
                    <div>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Career Opportunities</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Industry Events</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Newsroom</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Investors</a>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}  lg={4}>
                    <h3 className='font-semibold text-xl mb-4'>Our Policies</h3>
                    <div>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Terms and Conditions</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Privacy Policy</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Fees and Payments</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Legal Disclaimer</a>
                      <a href='#' className='block mb-3 text-sm hover:text-blue-600 transition-all duration-300 hover:ml-0.5'>Accessibility Statement</a>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='footer-copyright py-5'>
          <div className='container px-4 mx-auto'>
            <p className='m-0 text-sm'>Copyright © 2024 Agosibillo All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  )
}
