import React from 'react'

export default function BannerDashboard(props) {
  return (
    <>
        <section className='relative innerbanner'>
            <img src='assets/images/banner.png' className='bannerImg'/>
            <h2 className='text-4xl font-bold absolute w-full top-20 text-white text-center'>{props.pageTitle}</h2>
        </section>
    </>
  )
}
