export const feeOptions = [
  "$0-$500",
  "Above $500",
  "Above $1000",
  "Above $2000",
];

export const Availabilities = [
  "Available in next 4 hours",
  "Available Today",
  "Available Tomorrow",
  "Available in next 7 days",
];

export const Experiences = [
  "5+",
  "10+",
  "15+",
  "20+",
];

export const sortingConditions = [
    "Fees Low-High",
    "Fees High-Low",
    "Experience High-Low",
    "Experience Low-High"
]
